import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

export const environment = {
  name: 'Beta',
  production: false,
  DOMAIN_WILDCARD: '.plasma.games',
  API_URL: 'https://beta-api.plasma.games/graphql',
  SCIOPS_API: 'https://beta-api.plasma.games/',
  plugins: [NgxsReduxDevtoolsPluginModule.forRoot(), NgxsLoggerPluginModule.forRoot()],
  SSO_URL_GOOGLE: 'https://beta-api.plasma.games/auth/google/login',
  SSO_URL_CLEVER: 'https://clever.com/oauth/authorize?response_type=code&client_id=b7b2290fcd7268ea925a&redirect_uri=https://beta-api.plasma.games/auth/clever/login',
  SSO_CLEVER_ENABLED: false,
  GOOGLE_TAG_ID: 'GTM-K8LCKC4F',
  AMPLITUDE_ID: '',
  AMPLITUDE_URL: 'https://beta-api.plasma.games/amplitude',
};
